import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className="footer-container">
      <div className="footer-container1">
        <footer className="footer-footer">
          <div className="footer-container2">
            <Link to="/" className="footer-navlink">
              <img
                alt={props.image_alt}
                src={props.image_src}
                className="footer-image"
              />
            </Link>
            <nav className="footer-nav">
              <Link to="/educational-workshops" className="footer-navlink1">
                {props.text1}
              </Link>
              <Link to="/community-projects" className="footer-navlink2">
                {props.text2}
              </Link>
              <Link to="/team-activities" className="footer-navlink3">
                {props.text3}
              </Link>
              <Link to="/mentorship-sessions" className="footer-navlink4">
                {props.text4}
              </Link>
            </nav>
          </div>
          <div className="footer-separator"></div>
          <div className="footer-container3">
            <span className="footer-text">{props.text}</span>
            <div className="footer-container4">
              <div className="footer-container5">
                <a
                  href="https://wa.me/2724308106"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link"
                >
                  <svg viewBox="0 0 1024 1024" className="footer-icon">
                    <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/Inspireteensa"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link1"
                >
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="footer-icon2"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/savethegirlssa/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link2"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="footer-icon4"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  text: '© 2023 Save The Girls Pty Ltd, All Rights Reserved.',
  text1: 'Educational Workshops',
  image_src: '/assets/logo-square-transparent-1500h.png',
  text4: 'Mentorship Sessions',
  image_alt: 'logo',
  text2: 'Community Projects',
  text3: 'Team Activities',
}

Footer.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  image_src: PropTypes.string,
  text4: PropTypes.string,
  image_alt: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
}

export default Footer
